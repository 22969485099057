<template>
  <details
    class="v-details-block"
    :aria-expanded="open"
    :open="open"
    data-test-id="details-block"
  >
    <summary data-test-id="details-block-summary">
      <span
        v-if="title"
        class="summary-title"
        v-html="sanitize(title)"
      />
      <div class="v-summary-chevron-down">
        <icon-old-general-chevron-right />
      </div>
    </summary>
    <div
      class="v-summary-content"
      :class="[
        inElement ? 'v-summary-content-in-element' : 'v-summary-content-absolute'
      ]"
    >
      <slot />
    </div>
    <div class="v-summary-chevron-up">
      <icon-old-general-chevron-right />
    </div>
  </details>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    inElement?: boolean
    open?: boolean
    title?: string | null
  }>(),
  {
    inElement: false,
    open: false,
    title: null
  }
)

const { sanitize } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/variables';

$details-padding: 1em;

.v-details-block {
  margin: 8px auto;
  width: 100%;
  border-radius: variables.$BorderRadius;
  border: variables.$BorderWidth solid variables.$BorderColor;
  position: relative;

  .summary-title {
    color: variables.$BodyTextColorLight;
    user-select: none;
  }

  &:hover {
    cursor: pointer;
  }

  &[open] summary {
    .v-summary-chevron-down {
      opacity: 0;
    }
  }

  .v-summary-content-absolute {
    position: absolute;
    top: 75%;
    left: 0;
    width: 100%;
    right: 0;
    z-index: 5;

    border-top: none;
    border-right: variables.$BorderWidth solid variables.$BorderColor;
    border-left: variables.$BorderWidth solid variables.$BorderColor;
    border-bottom: variables.$BorderWidth solid variables.$BorderColor;

    border-radius: 0 0 variables.$BorderRadius variables.$BorderRadius;
  }

  .v-summary-content-in-element {
    border-radius: variables.$BorderRadius;
  }

  .v-summary-content {
    cursor: default;
    padding: $details-padding;
    font-weight: 400;
    line-height: 1.5;
    background: variables.$PopupBackground;
    color: variables.$PopupColor;

    ul {
      padding: 0;
      margin: 0;
    }
  }

  summary {
    list-style: none;
    padding: $details-padding;

    &:focus {
      outline: none;
    }

    &:hover .v-summary-chevron-up i {
      opacity: 1;
    }
  }

  .v-summary-chevron-up i {
    opacity: 0.5;
  }

  .v-summary-chevron-up,
  .v-summary-chevron-down {
    pointer-events: none;
    position: absolute;
    top: 1em;
    right: $details-padding;
  }

  .v-summary-chevron-up {
    svg {
      transform: rotate(-90deg);
    }
  }
  .v-summary-chevron-down {
    transform: rotate(90deg);
  }

  summary::-webkit-details-marker {
    display: none;
  }
}
</style>
